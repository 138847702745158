import React, { useEffect } from 'react';
import { Typography, Container, Button, Box } from '@mui/material';
import AceEditor from 'react-ace';
import { useNavigate } from 'react-router';
import img05 from '../../../assets/docs/05.png';
import img06 from '../../../assets/docs/06.png';

const style = {
  root: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  h3: {
    borderBottom: '1px solid #1976d2',
    marginBottom: '20px',
  },
  img: {
    width: '100%',
    MozBoxShadow: '10px 10px 5px #ccc',
    WebkitBoxShadow: '10px 10px 5px #ccc',
    boxShadow: '10px 10px 5px #ccc',
    MozBorderRadius: '25px',
    WebkitBorderRadius: '25px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};

const Styling = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container style={style.root}>
        <Typography component="h2" variant="h4">
          Styling your template
        </Typography>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Define your CSS style
        </Typography>
        <Typography sx={{ marginBottom: '20px' }}>
          You can style your documents using CSS3 syntax.
        </Typography>
        <AceEditor
          mode="css"
          fontSize={15}
          theme="chaos"
          /* eslint-disable-next-line */
          value={"h1 {\n  font-style: italic;\n  text-align: center;\n}\nh2 {\n color: blue\n}\n"}
          minLines={10}
          width="100%"
          readOnly
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            highlightActiveLine: true,
            showLineNumbers: true,
          }}
        />
        <Box sx={{ marginBottom: '20px' }} />
        <Typography sx={{ marginTop: '20px', marginBottom: '20px' }}>
          You can see result in live preview at the right.
        </Typography>
        <img style={style.img} src={img05} alt="" />
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Import UI library
        </Typography>
        <Box sx={{ marginBottom: '20px' }} />
        <Typography>
          You can import your own CSS file with simply use the @import instruction. It&apos;s
          working with font, external css files, backgrounds images etc...
        </Typography>
        <Box sx={{ marginBottom: '20px' }} />
        <AceEditor
          mode="css"
          fontSize={15}
          theme="chaos"
          value="@import url('https://unpkg.com/material-components-web@latest/dist/material-components-web.min.css');"
          minLines={5}
          width="100%"
          readOnly
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            highlightActiveLine: true,
            showLineNumbers: true,
          }}
        />
        <Box sx={{ marginBottom: '20px' }} />
        <Typography>And use it in the HTML editor</Typography>
        <Box sx={{ marginBottom: '20px' }} />
        <AceEditor
          mode="html"
          fontSize={15}
          theme="chaos"
          /* eslint-disable-next-line */
          value={"<h1>Hello {{ data.name }}</h1>\n<p>My email is {{ @data.email }}</p>\n<p>This is a material button : </p>\n<button class=\"mdc-button foo-button\">\n  <div class=\"mdc-button__ripple\"></div>\n  <span class=\"mdc-button__label\">Button</span>\n</button>"}
          minLines={10}
          width="100%"
          readOnly
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            highlightActiveLine: true,
            showLineNumbers: true,
          }}
        />
        <Container style={style.root}>
          <img style={style.img} src={img06} alt="" />
        </Container>
      </Container>
      <Container style={style.root} sx={{ textAlign: 'center' }}>
        <Button
          onClick={() => navigate('/docs/html')}
          variant="outlined"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          &lt; Creating template
        </Button>
        <Button
          onClick={() => navigate('/docs/api')}
          variant="contained"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          Using REST API &gt;
        </Button>
      </Container>
    </>
  );
};
export default Styling;
