import React, { useState } from 'react';
import {
  Grid,
  Tabs,
  FormControl,
  MenuItem,
  TextField,
  Box,
  Typography,
  Tab,
  useTheme,
  InputLabel,
  Select,
  FormHelperText,
} from '@mui/material';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AceEditor from 'react-ace';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Cookies from 'js-cookie';
// import ClassicEditor from '../../../../components/Editor/ckeditor';
// import ClassicEditor from 'ckeditor5/packages/ckeditor5-build-classic';
import FullEditor from 'ckeditor5-build-full';
import { PAGE_SIZES } from '../../../../utilities/page_size';
/* eslint-disable-next-line */
import '../../../../components/Themes/index.js';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const style = {
  select: {
    marginTop: '16px',
  },
  actions: {
    margin: 'auto',
    textAlign: 'right',
    paddingBottom: '12px',
  },
};

const SimpleTemplate = ({ template, setTemplate, preview, setHtml, html }) => {
  const [tab, setTab] = useState(0);
  const theme = useTheme();

  const handleChangeTab = (event, value) => {
    setTab(value);
  };

  const handleChangeIndex = (index) => {
    setTab(index);
  };

  const editorChange = (name, value) => {
    setTemplate({ ...template, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplate({ ...template, [name]: value });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="RichText mode" {...a11yProps(0)} />
          <Tab label="Sample Data" {...a11yProps(1)} />
          <Tab label="Settings" {...a11yProps(2)} />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tab}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={tab} index={0} dir={theme.direction}>
            {html && template.id && (
              <CKEditor
                editor={FullEditor}
                data={html}
                /* onReady={(editor) => {
                  editor &&
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        'min-height',
                        '500px',
                        editor.editing.view.document.getRoot()
                      );
                    });
                }} */
                onChange={(_, editor) => {
                  const data = editor.getData();
                  console.log(data);
                  setHtml(data || '<p></p>');
                }}
                config={{
                  simpleUpload: {
                    uploadUrl: `${process.env.REACT_APP_API_URL}/templates/upload/${template.id}`,
                    headers: { Authorization: `Bearer ${Cookies.get('token')}` },
                  },
                  image: {
                    resizeUnit: 'px',
                    resizeOptions: [
                      {
                        name: 'resizeImage:original',
                        label: 'Original',
                        value: null,
                      },
                      {
                        name: 'resizeImage:100',
                        label: '100px',
                        value: '100',
                      },
                      {
                        name: 'resizeImage:200',
                        label: '200px',
                        value: '200',
                      },
                    ],
                  },
                  toolbar: {
                    removeItems: ['restrictedEditingException', 'mediaEmbed'],
                    shouldNotGroupWhenFull: true,
                  },
                }}
              />
            )}
          </TabPanel>
          <TabPanel value={tab} index={1} dir={theme.direction}>
            <AceEditor
              mode="json"
              fontSize={parseInt(Cookies.get('font_size'), 10)}
              theme={Cookies.get('theme')}
              maxLines={Cookies.get('max_lines')}
              value={template.sample}
              width="100%"
              onChange={(value) => editorChange('sample', value)}
              name="sample"
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                highlightActiveLine: true,
                showLineNumbers: true,
              }}
            />
          </TabPanel>
          <TabPanel value={tab} index={2} dir={theme.direction}>
            <Grid container spacing={1}>
              <Grid item xs={preview ? 12 : 6}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={template.title}
                  onChange={handleChange}
                />
                <FormControl style={style.select} fullWidth>
                  <InputLabel>Orientation</InputLabel>
                  <Select
                    value={template.orientation}
                    name="orientation"
                    label="Orientation"
                    onChange={handleChange}
                  >
                    <MenuItem value="Portrait">Portrait</MenuItem>
                    <MenuItem value="Landscape">Landscape</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Format</InputLabel>
                  <Select
                    value={template.page_size}
                    name="page_size"
                    label="Format"
                    onChange={handleChange}
                  >
                    {PAGE_SIZES.map((row) => (
                      <MenuItem value={row.format}>{row.format}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {PAGE_SIZES.filter((row) => row.format === template.page_size).pop().size}
                  </FormHelperText>
                </FormControl>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Margin Top"
                      name="margin_top"
                      value={template.margin_top}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Margin Bottom"
                      name="margin_bottom"
                      margin="normal"
                      value={template.margin_bottom}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Margin Left"
                      name="margin_left"
                      value={template.margin_left}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Margin Right"
                      name="margin_right"
                      value={template.margin_right}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};
export default SimpleTemplate;
