import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useFeedback } from '../../hooks/useFeedback';

const Feedback = () => {
  const [{ open, severity, message }, setFeedback] = useFeedback();

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={6000}
      onClose={() => {
        setFeedback((prevFeedback) => ({ ...prevFeedback, open: false }));
      }}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default Feedback;
