import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Image from 'material-ui-image';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ApiIcon from '@mui/icons-material/Api';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Avatar, ListItemText, ListItemAvatar, Stack, Divider } from '@mui/material';
import { purple } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import topImage from '../../assets/picture1.png';
import feature1 from '../../assets/picture2.png';
import feature2 from '../../assets/picture3.png';
import feature3 from '../../assets/picture4.png';
import logo from '../../assets/logo.png';

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '1 application',
      'Unlimited templates',
      '400 documents per month',
      '1 user',
      'Email support',
    ],
    buttonText: 'Get started for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '20',
    description: [
      '5 applications',
      'Unlimited templates',
      '3 000 documents per month',
      '5 users (coming soon)',
      'Email and chat support',
    ],
    buttonText: '30 days free trial',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '250',
    description: [
      'Unlimited application',
      'Unlimited templates',
      '20 000 documents per month',
      '10 users (coming soon)',
      'Email and phone support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

/* const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
]; */

const style = {
  logo: {
    maxHeight: '95px',
  },
  logo_block: {
    marginTop: '-45px',
  },
  mainValue: {
    marginBottom: '50px',
  },
  secondaryValue: {
    marginTop: '25px',
  },
  feature: {
    marginTop: '10px',
  },
  title: {
    marginTop: '20px',
    fontWeight: 'bold',
  },
  img: {
    width: '90%',
    MozBoxShadow: '10px 10px 5px #ccc',
    WebkitBoxShadow: '10px 10px 5px #ccc',
    boxShadow: '10px 10px 5px #ccc',
    MozBorderRadius: '25px',
    WebkitBorderRadius: '25px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};

const Homepage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        /* eslint-disable-next-line */
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            {matches ? 'FastPdf.io' : ''}
          </Typography>
          <nav>
            <Link variant="outlined" color="text.secondary" href="/login" sx={{ my: 1, mx: 1.5 }}>
              Login
            </Link>
            <Button href="/register" color="secondary" variant="contained" sx={{ my: 1, mx: 1.5 }}>
              Get Started for FREE
            </Button>
          </nav>
        </Toolbar>
      </AppBar>
      {!matches && (
        <Container style={style.logo_block}>
          <img src={logo} alt="FastPdf.io logo" style={style.logo} />
        </Container>
      )}
      <main>
        <Box
          sx={{
            bgcolor: 'background.paper',
            // height: '100vh',
            pt: 8,
          }}
        >
          <Container>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
              style={style.mainValue}
            >
              Automate your PDF
              <br />
              generation
            </Typography>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h5"
                  color="text.secondary"
                  style={style.secondaryValue}
                  paragraph
                >
                  FastPDF.io allow you generate PDF files from pre-defined templates and JSON data.
                </Typography>
                <Typography
                  variant="body"
                  color="text.secondary"
                  style={style.secondaryValue}
                  paragraph
                >
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: purple[500] }}>
                          <PictureAsPdfIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Create templates from HTML string or friendly interface (low code)" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: purple[500] }}>
                          <FlipCameraAndroidIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Insert dynamic data" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: purple[500] }}>
                          <ApiIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Use our REST API for automation" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: purple[500] }}>
                          <QueryStatsIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Monitor usage in realtime" />
                    </ListItem>
                  </List>
                </Typography>
                <Stack sx={{ pt: 4 }} direction="row" spacing={2} justifyContent="center">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/register')}
                  >
                    Get started for free
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => navigate('/docs')}>
                    Read documentation
                  </Button>
                </Stack>
                <Container sx={{ pt: 2, textAlign: 'center' }}>
                  <a
                    href="https://www.producthunt.com/posts/fastpdf-io?utm_source=badge-review&utm_medium=badge&utm_souce=badge-fastpdf-io#discussion-body"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=329848&theme=light"
                      alt="FastPdf.io - Automate your PDF generation | Product Hunt"
                      width="250"
                      height="54"
                    />
                  </a>
                </Container>
              </Grid>
              <Grid item xs={false} md={6}>
                <Image src={topImage} imageStyle={{ width: 'unset' }} />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
          <Typography component="h2" variant="h3" align="center" color="text.primary" gutterBottom>
            Features
          </Typography>
          <Divider />
        </Container>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" color="secondary" component="p" style={style.title}>
                User friendly interface
              </Typography>
              <Typography variant="h6" color="text.secondary" component="p" style={style.feature}>
                Create and manage PDF templates from HTML/CSS or with
                {` `}our editor to reduce development and support costs (low code).
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <img src={feature1} alt="Feature 1" style={style.img} />
              </Box>
            </Grid>
          </Grid>
          <Grid container direction={matches ? 'column-reverse' : 'row'}>
            <Grid item xs={12} md={6}>
              <img src={feature2} alt="Feature 2" style={style.img} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" color="secondary" component="p" style={style.title}>
                A simple API
              </Typography>
              <Typography variant="h6" color="text.secondary" component="p" style={style.feature}>
                Use our REST API with JSON data and create dynamic documents in few secondes.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" color="secondary" component="p" style={style.title}>
                Live preview
              </Typography>
              <Typography variant="h6" color="text.secondary" component="p">
                Don&apos;t waste your time... Use realtime preview and make awesome PDF in few
                minutes.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={feature3} alt="Feature 3" style={style.img} />
            </Grid>
          </Grid>
        </Container>
        <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
          <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
            Pricing
          </Typography>
          <Divider />
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            component="p"
            style={style.feature}
          >
            Free trial. No credit card required
          </Typography>
        </Container>
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={tier.title} xs={12} sm={12} md={4}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      /* eslint-disable-next-line */
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.primary.main
                          : theme.palette.primary.main,
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary">
                        {tier.price}€
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /mo
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      onClick={() => navigate('/register')}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Container
        maxWidth="md"
        component="footer"
        sx={{
          /* eslint-disable-next-line */
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        {/* <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="text.secondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
                </Grid> */}
        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://fastpdf.io">
            FastPDF.io
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
      {/* End footer */}
    </>
  );
};
export default Homepage;
