import React, { useState, useEffect } from 'react';
import {
  TextField,
  Divider,
  Container,
  Box,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
} from '@mui/material';
import useAxios from 'axios-hooks';
import SaveIcon from '@mui/icons-material/Save';
import Cookies from 'js-cookie';
import AceEditor from 'react-ace';
import { useFeedback } from '../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK } from '../../../utilities/feedback';
import Title from '../../../components/Title';
import '@react-pdf-viewer/core/lib/styles/index.css';
/* eslint-disable-next-line */
import "../../../components/Themes/index.js";

const style = {
  root: {
    marginTop: '40px',
  },
  button: {
    marginTop: '20px',
  },
  input: {
    marginBottom: '20px',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
};

const Profil = () => {
  const [, setFeedback] = useFeedback();
  const [profil, setProfil] = useState({
    name: '',
    theme: 'ambiance',
    font_size: 18,
    max_lines: 30,
  });
  const [errors, setErrors] = useState({
    name: '',
    theme: '',
    font_size: '',
    max_lines: '',
  });

  const [, executePatch] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/profil`,
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [, fetchProfil] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/profil`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const updateProfil = () => {
    executePatch({ data: profil })
      .then(() => {
        setErrors({});
        setFeedback(
          SUCCESS_FEEDBACK({
            message: 'Profil updated',
          })
        );
        Cookies.set('theme', profil.theme);
        Cookies.set('font_size', profil.font_size);
        Cookies.set('max_lines', profil.max_lines);
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfil({ ...profil, [name]: value });
  };

  useEffect(() => {
    fetchProfil().then(({ data }) => setProfil({ ...profil, ...data }));
  }, []);

  return (
    <Box>
      <Title value="My Account" component="h1" variant="h1" />
      <Container style={style.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info">
              Your personal API key is : <b>{Cookies.get('api_key')}</b>
            </Alert>
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Your name"
              name="name"
              size="small"
              fullWidth
              style={style.input}
              error={errors.name}
              helperText={errors.name}
              value={profil.name}
              onChange={handleChange}
            />
            <TextField
              required
              label="Font size"
              name="font_size"
              size="small"
              fullWidth
              type="number"
              style={style.input}
              error={errors.font_size}
              helperText={errors.font_size}
              value={parseInt(profil.font_size, 10)}
              onChange={handleChange}
            />
            <TextField
              required
              label="Max lines"
              name="max_lines"
              size="small"
              fullWidth
              type="number"
              style={style.input}
              error={errors.max_lines}
              helperText={errors.max_lines}
              value={parseInt(profil.max_lines, 10)}
              onChange={handleChange}
            />
            <FormControl fullWidth required>
              <InputLabel>Theme</InputLabel>
              <Select
                value={profil.theme}
                name="theme"
                size="small"
                label="Thème"
                onChange={handleChange}
              >
                <MenuItem value="ambiance">Ambiance</MenuItem>
                <MenuItem value="chaos">Chaos</MenuItem>
                <MenuItem value="chrome">Chrome</MenuItem>
                <MenuItem value="clouds_midnight">Clouds Midnight</MenuItem>
                <MenuItem value="clouds">Clouds</MenuItem>
                <MenuItem value="cobalt">Cobalt</MenuItem>
                <MenuItem value="crimson_editor">Crimson Editor</MenuItem>
                <MenuItem value="dawn">Dawn</MenuItem>
                <MenuItem value="dracula">Dracula</MenuItem>
                <MenuItem value="dreamweaver">Dreamweaver</MenuItem>
                <MenuItem value="eclipse">Eclipse</MenuItem>
                <MenuItem value="github">Github</MenuItem>
                <MenuItem value="gob">Gob</MenuItem>
                <MenuItem value="gruvbox">Gruvbox</MenuItem>
                <MenuItem value="idle_fingers">Idel Fingers</MenuItem>
                <MenuItem value="iplastic">Iplastic</MenuItem>
                <MenuItem value="kr_theme">KR Theme</MenuItem>
                <MenuItem value="kuroir">Kuroir</MenuItem>
                <MenuItem value="merbivore_soft">Merbivore Soft</MenuItem>
                <MenuItem value="merbivore">Merbivore</MenuItem>
                <MenuItem value="mono_industrial">Mono Industrial</MenuItem>
                <MenuItem value="monokai">Monokai</MenuItem>
                <MenuItem value="nord_dark">Nord Dark</MenuItem>
                <MenuItem value="one_dark">One Dark</MenuItem>
                <MenuItem value="pastel_on_dark">Pastel On Dark</MenuItem>
                <MenuItem value="solarized_dark">Solarized Dark</MenuItem>
                <MenuItem value="solarized_light">Solarized Light</MenuItem>
                <MenuItem value="sqlserver">SQL Server</MenuItem>
                <MenuItem value="terminal">Terminal</MenuItem>
                <MenuItem value="textmate">Textmate</MenuItem>
                <MenuItem value="tomorrow_night_blue">Tomorrow Night Blue</MenuItem>
                <MenuItem value="tomorrow_night_bright">Tomorrow Night Bright</MenuItem>
                <MenuItem value="tomorrow_night_eighties">Tomorrow Night Eighties</MenuItem>
                <MenuItem value="tomorrow_night">Tomorrow Night</MenuItem>
                <MenuItem value="tomorrow">Tomorrow</MenuItem>
                <MenuItem value="twilight">Twilight</MenuItem>
                <MenuItem value="vibrant_ink">Vibrand Ink</MenuItem>
                <MenuItem value="xcode">Xcode</MenuItem>
              </Select>
            </FormControl>
            <Button
              style={style.button}
              variant="contained"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={() => updateProfil()}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={6}>
            <AceEditor
              mode="html"
              theme={profil.theme}
              fontSize={parseInt(profil.font_size, 10)}
              value="<h1>Hello World !</h1>"
              height="100px"
              width="100%"
              onChange={null}
              name="sample"
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                highlightActiveLine: true,
                showLineNumbers: true,
              }}
            />
            <Divider style={style.divider} />
            <AceEditor
              mode="css"
              theme={profil.theme}
              fontSize={parseInt(profil.font_size, 10)}
              value=".h1 { color: red }"
              height="100px"
              readOnly
              width="100%"
              onChange={null}
              name="sample"
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                highlightActiveLine: true,
                showLineNumbers: true,
              }}
            />
            <Divider style={style.divider} />
            <AceEditor
              mode="json"
              theme={profil.theme}
              fontSize={parseInt(profil.font_size, 10)}
              value='{ "name: "John DOE" }'
              height="100px"
              readOnly
              width="100%"
              onChange={null}
              name="sample"
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                highlightActiveLine: true,
                showLineNumbers: true,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Profil;
