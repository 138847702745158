import React, { useState } from 'react';
import {
  Grid,
  Tabs,
  FormControl,
  MenuItem,
  TextField,
  Box,
  Typography,
  Tab,
  useTheme,
  InputLabel,
  FormHelperText,
  Select,
} from '@mui/material';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AceEditor from 'react-ace';
import Cookies from 'js-cookie';
/* eslint-disable-next-line */
import '../../../../components/Themes/index.js';
import { PAGE_SIZES } from '../../../../utilities/page_size';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const AdvancedTemplate = ({ template, setTemplate, preview, setHtml, html }) => {
  const [tab, setTab] = useState(0);
  const theme = useTheme();

  const handleChangeTab = (event, value) => {
    setTab(value);
  };

  const handleChangeIndex = (index) => {
    setTab(index);
  };

  const editorChange = (name, value) => {
    if (name === 'html') {
      setHtml(value);
    } else {
      setTemplate({ ...template, [name]: value });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplate({ ...template, [name]: value });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="HTML template" {...a11yProps(0)} />
          <Tab label="CSS style" {...a11yProps(1)} />
          <Tab label="Sample Data" {...a11yProps(2)} />
          <Tab label="Settings" {...a11yProps(3)} />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tab}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={tab} index={0} dir={theme.direction}>
            <AceEditor
              mode="html"
              fontSize={parseInt(Cookies.get('font_size'), 10)}
              theme={Cookies.get('theme')}
              maxLines={Cookies.get('max_lines')}
              value={html}
              width="100%"
              onChange={(value) => editorChange('html', value)}
              name="html"
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                highlightActiveLine: true,
                showLineNumbers: true,
              }}
            />
          </TabPanel>
          <TabPanel value={tab} index={1} dir={theme.direction}>
            <AceEditor
              mode="css"
              fontSize={parseInt(Cookies.get('font_size'), 10)}
              theme={Cookies.get('theme')}
              maxLines={Cookies.get('max_lines')}
              value={template.css}
              width="100%"
              onChange={(value) => editorChange('css', value)}
              name="css"
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                highlightActiveLine: true,
                showLineNumbers: true,
              }}
            />
          </TabPanel>
          <TabPanel value={tab} index={2} dir={theme.direction}>
            <AceEditor
              mode="json"
              fontSize={parseInt(Cookies.get('font_size'), 10)}
              theme={Cookies.get('theme')}
              maxLines={Cookies.get('max_lines')}
              value={template.sample}
              width="100%"
              onChange={(value) => editorChange('sample', value)}
              name="sample"
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                highlightActiveLine: true,
                showLineNumbers: true,
              }}
            />
          </TabPanel>
          <TabPanel value={tab} index={3} dir={theme.direction}>
            <Grid container spacing={1}>
              <Grid item xs={preview ? 12 : 6}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  margin="normal"
                  value={template.title}
                  onChange={handleChange}
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Orientation</InputLabel>
                  <Select
                    value={template.orientation}
                    name="orientation"
                    label="Orientation"
                    onChange={handleChange}
                  >
                    <MenuItem value="Portrait">Portrait</MenuItem>
                    <MenuItem value="Landscape">Landscape</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Format</InputLabel>
                  <Select
                    value={template.page_size}
                    name="page_size"
                    label="Format"
                    onChange={handleChange}
                  >
                    {PAGE_SIZES.map((row) => (
                      <MenuItem value={row.format}>{row.format}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {PAGE_SIZES.filter((row) => row.format === template.page_size).pop().size}
                  </FormHelperText>
                </FormControl>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Margin Top"
                      name="margin_top"
                      value={template.margin_top}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Margin Bottom"
                      name="margin_bottom"
                      margin="normal"
                      value={template.margin_bottom}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Margin Left"
                      name="margin_left"
                      value={template.margin_left}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Margin Right"
                      name="margin_right"
                      value={template.margin_right}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
};
export default AdvancedTemplate;
