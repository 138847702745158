import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Alert } from '@mui/material';
import { Line } from 'react-chartjs-2';
import useAxios from 'axios-hooks';
import Loader from '../../../../components/Loader';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const style = {
  chart: {
    maxHeight: '300px',
  },
};

const BarCount = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Documents',
      },
    },
  };

  const [labels, setLabels] = useState([]);
  const [dataset, setDataset] = useState({
    labels,
    datasets: [
      {
        label: 'Created',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  });

  const [{ data, loading }] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/stats/count`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    if (data) {
      const tmpLabels = [];
      const tmpValues = [];
      /* eslint-disable-next-line */
      for (const [key, value] of Object.entries(data)) {
        tmpLabels.push(key);
        tmpValues.push(value);
      }
      setLabels(tmpLabels);
      setDataset((prevChart) => ({
        ...prevChart,
        labels: tmpLabels,
        datasets: [{ ...prevChart.datasets[0], data: tmpValues }],
      }));
    }
  }, [data]);

  return (
    <>
      {loading && <Loader />}
      {!loading && dataset.datasets[0].data.length !== 0 ? (
        <Line options={options} data={dataset} style={style.chart} />
      ) : (
        <Alert severity="info">No data available</Alert>
      )}
    </>
  );
};
export default BarCount;
