import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TemplatesList from '../../containers/Templates/List';
import TemplateNew from '../../containers/Templates/New';
import TemplateInit from '../../containers/Templates/Init';
import DocumentsList from '../../containers/Documents/List';
import DocumentInit from '../../containers/Documents/Init';
import DocumentNew from '../../containers/Documents/New';
import WebhooksList from '../../containers/Webhooks/List';
import WebhookNew from '../../containers/Webhooks/New';
import AppsList from '../../containers/Apps/List';
import AppNew from '../../containers/Apps/New';
import Profil from '../../containers/Users/Profil';
import Statistics from '../../containers/Statistics';
import ChoosePlan from '../../containers/Plans/Choose';
import SuccessPlan from '../../containers/Plans/Success';

const Routing = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Statistics />} />
      <Route exact path="/profil" element={<Profil />} />
      <Route exact path="/templates" element={<TemplatesList />} />
      <Route exact path="/templates/initialize" element={<TemplateInit />} />
      <Route exact path="/templates/new" element={<TemplateNew />} />
      <Route exact path="/templates/:id/edit" element={<TemplateNew />} />
      <Route exact path="/documents" element={<DocumentsList />} />
      <Route exact path="/documents/initialize" element={<DocumentInit />} />
      <Route exact path="/documents/:id/edit" element={<DocumentNew />} />
      <Route exact path="/webhook_endpoints" element={<WebhooksList />} />
      <Route exact path="/webhook_endpoints/new" element={<WebhookNew />} />
      <Route exact path="/webhook_endpoints/:id/edit" element={<WebhookNew />} />
      <Route exact path="/apps" element={<AppsList />} />
      <Route exact path="/apps/new" element={<AppNew />} />
      <Route exact path="/apps/:id/edit" element={<AppNew />} />
      <Route exact path="/plans/choose" element={<ChoosePlan />} />
      <Route exact path="/plans/success" element={<SuccessPlan />} />
    </Routes>
  );
};
export default Routing;
