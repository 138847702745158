import React, { useState, useEffect, Fragment } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Alert } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import useAxios from 'axios-hooks';
import Loader from '../../../../components/Loader';

ChartJS.register(ArcElement, Tooltip, Legend);

const style = {
  chart: {
    maxHeight: '300px',
  },
};

const DoughnutTemplate = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Documents by template',
      },
    },
  };

  const [dataset, setDataset] = useState({
    labels: [],
    datasets: [
      {
        label: 'Documents by templates',
        data: [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  });

  const [{ data, loading }] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/stats/templates`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  useEffect(() => {
    if (data) {
      const tmpLabels = [];
      const tmpValues = [];
      /* eslint-disable-next-line */
      for (const [key, value] of Object.entries(data)) {
        tmpLabels.push(key);
        tmpValues.push(value);
      }
      setDataset((prevChart) => ({
        ...prevChart,
        labels: tmpLabels,
        datasets: [{ ...prevChart.datasets[0], data: tmpValues }],
      }));
    }
  }, [data]);

  return (
    <>
      {loading && <Loader />}
      {!loading && dataset.datasets[0].data.length !== 0 ? (
        <Doughnut options={options} data={dataset} style={style.chart} />
      ) : (
        <Alert severity="info">No data available</Alert>
      )}
    </>
  );
};
export default DoughnutTemplate;
