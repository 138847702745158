import React, { useEffect } from 'react';
import { Typography, Container, Button, Alert, List, ListItem } from '@mui/material';
import AceEditor from 'react-ace';
import { useNavigate } from 'react-router';

const style = {
  root: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  h3: {
    borderBottom: '1px solid #1976d2',
    marginBottom: '20px',
  },
  alert: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  img: {
    width: '100%',
    MozBoxShadow: '10px 10px 5px #ccc',
    WebkitBoxShadow: '10px 10px 5px #ccc',
    boxShadow: '10px 10px 5px #ccc',
    MozBorderRadius: '25px',
    WebkitBorderRadius: '25px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};

const Api = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container style={style.root}>
        <Typography component="h2" variant="h4">
          Generating a document with REST API
        </Typography>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Get your API key and Template ID
        </Typography>
        <Typography sx={{ marginTop: '20px', marginBottom: '20px' }}>
          For using our API, you need your <b>[API_KEY]</b> and <b>[TEMPLATE_ID]</b>. This
          informations are available in your profil and template&apos;s list.
        </Typography>
        <Typography component="h3" style={style.h3} variant="h5">
          Generate a document
        </Typography>
        <Typography sx={{ marginTop: '20px', marginBottom: '20px' }}>
          To generate a document, use this endpoint:{` `}
          <b>https://api.fastpdf.io/api/documents/generate</b>
          {` `}with HTTP POST request. This is a cURL example (with a data payload).
        </Typography>
        <AceEditor
          mode="emacs"
          fontSize={15}
          theme="chaos"
          /* eslint-disable */
          value={`curl 'https://api.fastpdf.io/api/documents/generate' \\
          -H 'Token: [API_KEY]' \\
          -H 'Content-Type: application/json' \\
          -d '{
                "document": {
                  "template": "[TEMPLATE_ID]",
                  "title": "Document title",
                  "payload": "{ \"name\": \"John Doe\" }",
                  "meta": "{ \"foo\": \"bar\" }"
                }
              }'`}
          /* eslint-enable */
          minLines={10}
          width="100%"
          readOnly
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            highlightActiveLine: true,
            showLineNumbers: true,
          }}
        />
        <Alert style={style.alert} severity="info">
          {/* eslint-disable-next-line */}
          Don't forget to replace [TEMPLATE_ID] and [API_KEY] by your own credentials
        </Alert>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          API response will look like this :
        </Typography>
        <AceEditor
          mode="json"
          fontSize={15}
          theme="chaos"
          /* eslint-disable-next-line */
          value={`{
  id: "47788203-6f84-4f9b-9b77-d20194542ad1",
  payload:"{ \\"name\\": \\"John Doe\\" }"
  meta:"{ \\"foo\\": \\"bar\\" }",
  created_at: "2021-12-20T10:37:24.935Z",
  updated_at: "2021-12-20T10:37:25.854Z",
  url: "https://api.fastpdf.io/files/47788203-6f84-4f9b-9b77-d20194542ad1.pdf",
  title: "Document title",
  state: "created",
  template_id: "e9a74ca1-40de-491a-ab88-b34b4775bd04"
}`}
          minLines={10}
          width="100%"
          readOnly
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            highlightActiveLine: true,
            showLineNumbers: true,
          }}
        />
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          PDF generation is asyncronous.
        </Typography>
        <Typography sx={{ marginTop: '20px', marginBottom: '20px' }}>
          You need to check if your document is ready. For that, call this API endpoint to get your
          document state.
        </Typography>
        <AceEditor
          mode="emacs"
          fontSize={15}
          theme="chaos"
          value={`curl 'https://api.fastpdf.io/api/documents/[DOCUMENT_ID]' \\
          -H 'Token: [API_KEY]'`}
          minLines={10}
          width="100%"
          readOnly
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            highlightActiveLine: true,
            showLineNumbers: true,
          }}
        />
        <Typography sx={{ marginTop: '20px' }}>A document can have differentes states</Typography>
        <List>
          <ListItem>
            <b>Draft</b>
            {` `} : Document is a draft
          </ListItem>
          <ListItem>
            <b>Pending</b>
            {` `} : Document is in queue for generation
          </ListItem>
          <ListItem>
            <b>Wip</b>
            {` `} : Document generation in progress
          </ListItem>
          <ListItem>
            <b>Created</b>
            {` `} : PDF document created
          </ListItem>
          <ListItem>
            <b>Error</b>
            {` `} : PDF document generation failed
          </ListItem>
        </List>
      </Container>
      <Container style={style.root} sx={{ textAlign: 'center' }}>
        <Button
          onClick={() => navigate('/docs/styling')}
          variant="outlined"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          &lt; Styling your template
        </Button>
        <Button
          onClick={() => navigate('/docs/webhook')}
          variant="contained"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          Use Webhook &gt;
        </Button>
      </Container>
    </>
  );
};
export default Api;
