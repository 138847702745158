import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  TextField,
  CircularProgress,
  Avatar,
  Button,
  Container,
  CssBaseline,
  Typography,
  Link,
  Collapse,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import HelpIcon from '@mui/icons-material/HelpOutline';
import axios from 'axios';

const style = {
  root: {
    margin: 'auto',
    width: '50%',
    textAlign: 'center',
  },
  avatar: {
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
  },
  typography: {
    marginBottom: '10px',
  },
  submit: {
    marginTop: '20px',
  },
  register: {
    marginTop: '20px',
    display: 'block',
    textDecoration: 'none',
  },
  alert: {
    marginBottom: '20px',
  },
};

const ResetForm = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const submitPassword = () => {
    setLoading(true);
    if (password !== passwordConfirmation) {
      setShowError(true);
      setLoading(false);
    } else {
      const params = {
        password,
        token,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/password/reset`, params)
        .then((_response) => {
          setShowSuccess(true);
          setShowError(false);
        })
        .catch((_e) => {
          setShowError(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container style={style.root}>
      <CssBaseline />
      <Container>
        <Avatar style={style.avatar}>
          <HelpIcon />
        </Avatar>
        <Typography style={style.typography}>Reset your password</Typography>
        {showError && (
          <Collapse in={showError}>
            <Alert severity="error" style={style.alert}>
              Password confirmation is incorrect
            </Alert>
          </Collapse>
        )}
        {showSuccess && (
          <Alert severity="success" style={style.alert}>
            Your password has been reset.
          </Alert>
        )}
        {showSuccess === false && (
          <form>
            <TextField
              id="password"
              label="New password"
              variant="outlined"
              size="small"
              required
              fullWidth
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              type="password"
              labelWidth={120}
            />
            <TextField
              variant="outlined"
              label="Password confirmation"
              required
              size="small"
              margin="normal"
              name="passwordConfirmation"
              onChange={(event) => setPasswordConfirmation(event.target.value)}
              fullWidth
              type="password"
              labelWidth={240}
            />
            <Button variant="contained" fullWidth color="primary" onClick={submitPassword}>
              {!loading ? 'Submit' : <CircularProgress color="inherit" />}
            </Button>
          </form>
        )}
        <Container style={style.root}>
          <Link href="/login" style={style.register}>
            Login page
          </Link>
        </Container>
      </Container>
    </Container>
  );
};
export default ResetForm;
