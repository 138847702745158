import React, { useEffect } from 'react';
import { Typography, Container, Button, Alert } from '@mui/material';
import { useNavigate } from 'react-router';
import AceEditor from 'react-ace';

const style = {
  root: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  h3: {
    borderBottom: '1px solid #1976d2',
    marginBottom: '20px',
  },
  img: {
    width: '100%',
    MozBoxShadow: '10px 10px 5px #ccc',
    WebkitBoxShadow: '10px 10px 5px #ccc',
    boxShadow: '10px 10px 5px #ccc',
    MozBorderRadius: '25px',
    WebkitBorderRadius: '25px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};

const Webhook = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container style={style.root}>
        <Typography component="h2" variant="h4">
          Setting a Webhook
        </Typography>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Configure a Webhook
        </Typography>
        <Typography sx={{ marginTop: '20px' }}>
          Documents generation is asyncronous. So you can configure webhooks to get notified when a
          document&apos;s generation is done.
        </Typography>
        <Typography sx={{ marginTop: '20px' }}>
          Go to the <b>Webhook page</b> and set a target URL. Then click on <b>Save</b> button.
        </Typography>
        <Typography sx={{ marginTop: '20px', marginBottom: '20px' }}>
          That all... When your document is ready, your webhooks will be called using POST request
          with a <code>Content-type: application/json</code> header with all document&apos;s
          informations.
        </Typography>
        <Alert severity="warning">Your webhooks must generate a 200 OK response.</Alert>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Testing your Webhook
        </Typography>
        <Typography sx={{ marginTop: '20px', marginBottom: '20px' }}>
          JSON content in your webhooks look like this :
        </Typography>
        <AceEditor
          mode="json"
          fontSize={15}
          theme="chaos"
          /* eslint-disable-next-line */
          value={`{
  id: "47788203-6f84-4f9b-9b77-d20194542ad1",
  payload:  {},
  created_at: "2021-12-20T10:37:24.935Z",
  updated_at: "2021-12-20T10:37:25.854Z",
  url: "https://api.fastpdf.io/files/47788203-6f84-4f9b-9b77-d20194542ad1.pdf",
  title: "Title's document",
  state: "created",
  template_id: "e9a74ca1-40de-491a-ab88-b34b4775bd04"
}`}
          minLines={10}
          width="100%"
          readOnly
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            highlightActiveLine: true,
            showLineNumbers: true,
          }}
        />
      </Container>
      <Container style={style.root} sx={{ textAlign: 'center' }}>
        <Button
          onClick={() => navigate('/docs/api')}
          variant="outlined"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          &lt; Using REST API
        </Button>
        <Button
          onClick={() => navigate('/register')}
          variant="contained"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          Create a account ?
        </Button>
      </Container>
    </>
  );
};
export default Webhook;
