import React from 'react';
import { Typography, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router';

const style = {
  root: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  h3: {
    borderBottom: '1px solid #1976d2',
    marginBottom: '20px',
  },
};

const Account = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container style={style.root}>
        <Typography component="h2" variant="h4">
          Getting started
        </Typography>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Create your acount
        </Typography>
        <Typography>
          To create an account, go to the <b>Register page</b> and fill in you email and password.
        </Typography>
        <Typography>
          Once your account created, fill in your credentials in the <b>Sign in page</b> to get
          access to your dashboard.
        </Typography>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Create your first app
        </Typography>
        <Typography>
          In your dashboard you can create apps that will regroup your templates and documents. To
          create an app, click on <b>Applications</b> in sidebar menu, click on{` `}
          <b>Create an application</b> button and give a name to your app. Then click on{` `}
          <b>Save</b> button.
        </Typography>
      </Container>
      <Container style={style.root} sx={{ textAlign: 'center' }}>
        <Button
          onClick={() => navigate('/docs/templates')}
          variant="contained"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          Creating templates &gt;
        </Button>
      </Container>
    </>
  );
};
export default Account;
