import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, Divider, styled, Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
// import 'ace-builds/src-noconflict/mode-html';
// import 'ace-builds/src-noconflict/ext-language_tools';
import useAxios from 'axios-hooks';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BugReportIcon from '@mui/icons-material/BugReport';
/* eslint-disable-next-line */
import { html_beautify } from 'js-beautify';
import SimpleTemplate from '../components/Simple';
import AdvancedTemplate from '../components/Advanced';
import { useFeedback } from '../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK } from '../../../utilities/feedback';
import Title from '../../../components/Title';

const style = {
  button: {
    marginLeft: '10px',
  },
};

const Action = styled(Box)(({ theme }) => ({
  margin: 'auto',
  textAlign: 'right',
  paddingBottom: '12px',
  [theme.breakpoints.down('md')]: {
    textAlign: 'start',
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'end',
  },
}));

const TemplateNew = () => {
  const { id } = useParams();
  const [, setFeedback] = useFeedback();
  const [rand, setRand] = useState(Math.random());
  const [preview, setPreview] = useState(true);
  const [html, setHtml] = useState('');
  const [template, setTemplate] = useState({
    title: '',
    mod: 'simple',
    html: '',
    css: '',
    sample: '',
    orientation: 'Portrait',
    page_size: 'A4',
    margin_top: 10,
    margin_bottom: 10,
    margin_left: 10,
    margin_right: 10,
  });

  const [, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/templates`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [, executePatch] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/templates/${id}`,
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [, fetchTemplate] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/templates/${id}/edit`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const createTemplate = () => {
    executePost({ data: template }).then(() => {
      setFeedback(
        SUCCESS_FEEDBACK({
          message: 'Template created',
        })
      );
    });
  };

  const updateTemplate = () => {
    executePatch({ data: { ...template, html } }).then(() => {
      setFeedback(
        SUCCESS_FEEDBACK({
          message: 'Template updated',
        })
      );
      setRand(Math.random());
    });
  };

  const createOrUpdate = () => (id ? updateTemplate() : createTemplate());

  useEffect(() => {
    if (id)
      fetchTemplate().then(({ data }) => {
        setTemplate({ ...template, ...data });
        setHtml(html_beautify(data.html, { indent_size: 2, type: 'html' }));
      });
  }, [id]);

  return (
    <Box>
      <Title value="Create Template" component="h1" variant="h1" />
      <Action>
        <Button
          style={style.button}
          variant="contained"
          color="secondary"
          startIcon={<SaveIcon />}
          onClick={() => createOrUpdate()}
        >
          Save
        </Button>
        <Button
          style={style.button}
          variant="contained"
          color="secondary"
          startIcon={preview ? <VisibilityIcon /> : <VisibilityOffIcon />}
          onClick={() => setPreview(!preview)}
        >
          Preview
        </Button>
        <Button
          style={style.button}
          variant="contained"
          color="secondary"
          startIcon={<BugReportIcon />}
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_API_URL}/templates/sample/${id}/html?rand=${rand}`,
              '_blank'
            )
          }
        >
          Debug
        </Button>
      </Action>
      <Grid container spacing={1}>
        <Grid item xs>
          {template.mod === 'simple' ? (
            <SimpleTemplate
              template={template}
              setTemplate={setTemplate}
              preview={preview}
              setHtml={setHtml}
              html={html}
            />
          ) : (
            <AdvancedTemplate
              template={template}
              setTemplate={setTemplate}
              preview={preview}
              setHtml={setHtml}
              html={html}
            />
          )}
        </Grid>
        {preview && (
          <>
            <Divider orientation="vertical" flexItem />
            <Grid item xs>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={`${process.env.REACT_APP_API_URL}/templates/sample/${id}/pdf?rand=${rand}`}
                  renderError={() => <Alert severity="error">Invalid data Payload.</Alert>}
                />
              </Worker>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
export default TemplateNew;
