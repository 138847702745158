import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  CardActions,
  Button,
  GlobalStyles,
  Alert,
} from '@mui/material';
import StarIcon from '@mui/icons-material/StarBorder';
import Title from '../../../components/Title';
import { usePlan } from '../../../hooks/usePlan';

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      '1 application',
      'Unlimited templates',
      '400 documents per month',
      '1 user',
      'Email support',
    ],
    buttonText: 'Choose this plan',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '20',
    description: [
      '5 applications',
      'Unlimited templates',
      '3 000 documents per month',
      '5 users (coming soon)',
      'Email and chat support',
    ],
    buttonText: 'Choose this plan',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '250',
    description: [
      'Unlimited application',
      'Unlimited templates',
      '20 000 documents per month',
      '10 users (coming soon)',
      'Email and phone support',
    ],
    buttonText: 'Choose this plan',
    buttonVariant: 'outlined',
  },
];

const ChoosePlan = () => {
  const [plan] = usePlan();

  const upgrade = (choice) => {
    if (choice === 'Pro') {
      window.open('https://buy.stripe.com/5kA3eo9XH1q5b5u8ww', '_blank');
    }
    if (choice === 'Enterprise') {
      window.open('https://buy.stripe.com/bIYcOYfi10m14H6fYZ', '_blank');
    }
    if (choice === 'AppSumo') {
      window.open('https://buy.stripe.com/cN216g2vf4Ch6PefZ0', '_blank');
    }
  };

  return (
    <>
      <Title value="Choose a Plan" component="h1" variant="h1" />
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      {plan && plan.label === 'AppSumo' && (
        <Grid item xs={12}>
          <Alert severity="info">
            You are currently in AppSumo special plan (49€ for one year)
          </Alert>
        </Grid>
      )}
      <Container maxWidth="md" component="main" style={{ marginTop: 20 }}>
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={12} md={4}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    /* eslint-disable-next-line */
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.primary.main
                        : theme.palette.primary.main,
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {tier.price}€
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      {tier.title === 'AppSumo' ? '/year' : '/mo'}
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  {tier.title !== 'Free' ? (
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      onClick={() => upgrade(tier.title)}
                      disabled={tier.title === plan.label}
                    >
                      {tier.title === plan.label ? 'Current plan' : tier.buttonText}
                    </Button>
                  ) : (
                    <p />
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};
export default ChoosePlan;
