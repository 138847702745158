import React, { useState } from 'react';
import {
  styled,
  Box,
  Button,
  Container,
  Alert,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Table,
  TablePagination,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Moment from 'react-moment';
import AddIcon from '@mui/icons-material/Add';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router';
import { usePlan } from '../../../hooks/usePlan';
import Title from '../../../components/Title';
import Actions from '../components/Actions';
import Loader from '../../../components/Loader';

const Action = styled(Container)(({ theme }) => ({
  marginBottom: '14px',
  textAlign: 'end',
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    textAlign: 'start',
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'end',
  },
}));

const AppsList = () => {
  const navigate = useNavigate();
  const [plan] = usePlan();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updated_at');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/apps`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'updated At',
    },
    {
      id: 'action',
      numeric: true,
      disablePadding: false,
      label: 'Actions',
    },
  ];

  /* eslint-disable-next-line */
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  /* eslint-disable-next-line */
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      /* eslint-disable-next-line */
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Box>
      <Title value="Applications" component="h1" variant="h1" />
      <Action>
        <Button
          size="small"
          disabled={plan.app <= plan.app_counter}
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => navigate('/app/apps/new')}
        >
          Create an Application
        </Button>
      </Action>
      {error && <Alert severity="error">{error}</Alert>}
      {loading && <Loader />}
      {!loading && (
        <Container>
          {plan.app <= plan.app_counter && (
            <Alert severity="warning">
              You are using all your apps slots <b>({`${plan.app_counter}/${plan.app}`})</b>. If you
              need more apps, upgrade your account
            </Alert>
          )}
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          <Moment format="YYYY-MM-DD HH:mm:ss">{row.updated_at}</Moment>
                        </TableCell>
                        <TableCell align="right">
                          <Actions id={row.id} refetch={refetch} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </Box>
  );
};
export default AppsList;
