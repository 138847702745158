import React, { useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Container,
  Typography,
  CircularProgress,
  Link,
  Box,
  styled,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useAxios from 'axios-hooks';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import { usePlan } from '../../../hooks/usePlan';

const style = {
  avatar: {
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: '#9c27b0',
  },
  typography: {
    marginBottom: '10px',
  },
  submit: {
    marginTop: '20px',
  },
  login: {
    marginTop: '20px',
    display: 'block',
    textDecoration: 'none',
  },
};

const Root = styled(Box)(({ theme }) => ({
  margin: 'auto',
  width: '50%',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: '30%',
  },
}));

const Register = () => {
  const [register, setRegister] = useState({ email: '', password: '', appsumo: '' });
  const [, setPlan] = usePlan();
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    appsumo: '',
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegister({ ...register, [name]: value });
  };

  const [, postRegister] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/register`,
      method: 'POST',
    },
    { manual: true }
  );

  const registration = () => {
    setErrors('');
    setLoading(true);
    postRegister({ data: register })
      .then((result) => {
        setLoading(false);
        Cookies.set('token', result.data.token);
        Cookies.set('theme', result.data.theme);
        Cookies.set('api_key', result.data.api_key);
        setPlan({
          label: result.data.plan.label,
          app: parseInt(result.data.plan.app, 10),
          app_counter: parseInt(result.data.plan.app_counter, 10),
          document: parseInt(result.data.plan.document, 10),
          document_counter: parseInt(result.data.plan.document_counter, 10),
        });
        navigate('/app/');
      })
      .catch((error) => {
        setLoading(false);
        setErrors(error.response.data);
      });
  };

  return (
    <Root>
      <Container>
        <CssBaseline />
        <Avatar style={style.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography style={style.typography}>Registration</Typography>
        <form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            onChange={handleChange}
            label="Email"
            name="email"
            autoComplete="email"
            error={errors.email}
            helperText={errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            name="password"
            onChange={handleChange}
            label="Password"
            type="password"
            error={errors.password}
            helperText={errors.password}
          />
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            name="password_confirmation"
            onChange={handleChange}
            label="Password confirmation"
            type="password"
            error={errors.password_confirmation}
            helperText={errors.password_confirmation}
          />
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            fullWidth
            onChange={handleChange}
            label="Your AppSumo code"
            name="appsumo"
            error={errors.appsumo}
            helperText={errors.appsumo}
          />
          <Button
            style={style.submit}
            fullWidth
            variant="contained"
            color="primary"
            onClick={registration}
          >
            {loading ? <CircularProgress color="secondary" size={25} /> : `Register`}
          </Button>
        </form>
      </Container>
      <Container style={style.root}>
        <Link href="/login" style={style.login}>
          Already a account ?
        </Link>
      </Container>
    </Root>
  );
};
export default Register;
