import React, { useState } from 'react';
import {
  styled,
  Box,
  Button,
  CircularProgress,
  Chip,
  Alert,
  Container,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TableContainer,
  Table,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Moment from 'react-moment';
import Title from '../../../components/Title';
import Actions from '../components/Actions';
import Loader from '../../../components/Loader';

const style = {
  button: {
    marginLeft: '10px',
  },
};

const Action = styled(Container)(({ theme }) => ({
  marginBottom: '14px',
  textAlign: 'end',
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    textAlign: 'start',
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'end',
  },
}));

const DocumentsList = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updated_at');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/documents`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  const headCells = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: '#',
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: 'State',
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      label: 'Title',
    },
    {
      id: 'template',
      numeric: false,
      disablePadding: false,
      label: 'Template',
    },
    {
      id: 'updated_at',
      numeric: false,
      disablePadding: false,
      label: 'Updated At',
    },
    {
      id: 'action',
      numeric: true,
      disablePadding: false,
      label: 'Actions',
    },
  ];

  /* eslint-disable-next-line */
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  /* eslint-disable-next-line */
  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      /* eslint-disable-next-line */
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const documentIcon = ({ state }) => {
    switch (state) {
      case 'draft':
        return <InfoIcon />;
      case 'pending':
        return <InfoIcon />;
      case 'wip':
        return <WarningIcon />;
      case 'created':
        return <CheckCircleIcon />;
      default:
        return '';
    }
  };

  const documentColor = ({ state }) => {
    switch (state) {
      case 'draft':
        return 'info';
      case 'pending':
        return 'warning';
      case 'wip':
        return 'warning';
      case 'created':
        return 'success';
      case 'error':
        return 'error';
      default:
        return 'success';
    }
  };

  return (
    <Box>
      <Title value="Documents" component="h1" variant="h1" />
      <Action>
        <Button
          style={style.button}
          size="small"
          variant="contained"
          color="secondary"
          startIcon={loading ? <CircularProgress color="secondary" size={20} /> : <RefreshIcon />}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
        <Button
          style={style.button}
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => navigate('/app/documents/initialize')}
        >
          Create a document
        </Button>
      </Action>
      {error && <Alert severity="error">{error}</Alert>}
      {loading && <Loader />}
      {!loading && (
        <Container>
          {data.length === 0 && (
            <Alert severity="warning" sx={{ marginBottom: '10px' }}>
              Get started, make your first PDF document
            </Alert>
          )}
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>
                          {row.state === 'error' ? (
                            <Tooltip title={row.error}>
                              <Chip
                                icon={documentIcon(row)}
                                color={documentColor(row)}
                                label={row.state}
                                variant="outlined"
                              />
                            </Tooltip>
                          ) : (
                            <Chip
                              icon={documentIcon(row)}
                              color={documentColor(row)}
                              label={row.state}
                              variant="outlined"
                            />
                          )}
                        </TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{row.template}</TableCell>
                        <TableCell>
                          <Moment format="YYYY-MM-DD HH:mm:ss">{row.updated_at}</Moment>
                        </TableCell>
                        <TableCell align="right">
                          <Actions id={row.id} state={row.state} refetch={refetch} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Container>
      )}
    </Box>
  );
};
export default DocumentsList;
