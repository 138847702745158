import React from 'react';
import {
  Container,
  Box,
  GlobalStyles,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Link,
  Button,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import Account from './Account';
import Templates from './Templates';
import Html from './Html';
import Styling from './Styling';
import Api from './Api';
import Webhook from './Webhook';

const Documentation = () => {
  return (
    <Box styles={{ marginBottom: '20px' }}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        color="primary"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <Link href="/" underline="none" sx={{ color: '#FFFFFF' }}>
              FastPDF.io
            </Link>
          </Typography>
          <nav>
            <Link variant="outlined" color="text.secondary" href="/login" sx={{ my: 1, mx: 1.5 }}>
              Login
            </Link>
            <Button href="/register" color="secondary" variant="contained" sx={{ my: 1, mx: 1.5 }}>
              Get Started for FREE
            </Button>
          </nav>
        </Toolbar>
      </AppBar>
      <Container sx={{ margin: 'auto', marginTop: '20px', marginBottom: '40px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9}>
            <Routes>
              <Route exact path="/" element={<Account />} />
              <Route exact path="/templates" element={<Templates />} />
              <Route exact path="/html" element={<Html />} />
              <Route exact path="/styling" element={<Styling />} />
              <Route exact path="/api" element={<Api />} />
              <Route exact path="/webhook" element={<Webhook />} />
            </Routes>
          </Grid>
          <Grid item xs={false} sm={3}>
            <Typography variant="h5" compopent="h1">
              Documentation
            </Typography>
            <List>
              <ListItem>
                <Link sx={{ textDecoration: 'none' }} href="/docs">
                  Getting started
                </Link>
              </ListItem>
              <ListItem>
                <Link sx={{ textDecoration: 'none' }} href="/docs/templates">
                  Creating templates
                </Link>
              </ListItem>
              <ListItem>
                <Link sx={{ textDecoration: 'none' }} href="/docs/html">
                  Dynamic data
                </Link>
              </ListItem>
              <ListItem>
                <Link sx={{ textDecoration: 'none' }} href="/docs/styling">
                  Styling your PDF
                </Link>
              </ListItem>
              <ListItem>
                <Link sx={{ textDecoration: 'none' }} href="/docs/api">
                  Using REST API
                </Link>
              </ListItem>
              <ListItem>
                <Link sx={{ textDecoration: 'none' }} href="/docs/webhook">
                  Using Webhook
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Documentation;
