import React, { useEffect } from 'react';
import { Typography, Container, Button, ListItem, List } from '@mui/material';
import AceEditor from 'react-ace';
import { useNavigate } from 'react-router';
import img04 from '../../../assets/docs/04.png';

const style = {
  root: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  h3: {
    borderBottom: '1px solid #1976d2',
    marginBottom: '20px',
  },
  img: {
    width: '100%',
    MozBoxShadow: '10px 10px 5px #ccc',
    WebkitBoxShadow: '10px 10px 5px #ccc',
    boxShadow: '10px 10px 5px #ccc',
    MozBorderRadius: '25px',
    WebkitBorderRadius: '25px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};

const Html = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container style={style.root}>
        <Typography component="h2" variant="h4">
          HTML editor and Dynamic data
        </Typography>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Define your HTML Body
        </Typography>
        <Typography>
          The core of a template is HTML. You can structure your document by defining paragraphs
          with <b>&lt;p&gt;</b> or headings with <b>&lt;h1&gt;</b>, <b>&lt;h2&gt;</b>, …. You can
          use <b>&lt;div&gt;</b>, <b>&lt;span&gt;</b> or <b>&lt;img&gt;</b> elements.
        </Typography>
        <Typography>You can use lot of HTML tags.</Typography>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Dynamic Data with Liquid syntax
        </Typography>
        <Typography>
          You can use{` `}
          <a href="https://shopify.github.io/liquid/" target="_blank" rel="noreferrer">
            Liquid syntax
          </a>
          {` `}
          to insert dynamic data in your template. Liquid syntax allow you to :
        </Typography>
        <List>
          <ListItem>Insert content in your template</ListItem>
          <ListItem>Add conditions</ListItem>
          <ListItem>Display list if items</ListItem>
          <ListItem>Use powerful transformations</ListItem>
        </List>
        <Typography>
          To add sample data in your template, use <b>Sample data</b> tab
        </Typography>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Sample data example
        </Typography>
        <Typography sx={{ marginBottom: '20px' }}>
          We declare variables in a JSON structure :
        </Typography>
        <AceEditor
          mode="json"
          fontSize={15}
          theme="chaos"
          /* eslint-disable-next-line */
          value={"{\n  \"name\": \"John DOE\",\n  \"email\": \"contact@fastpdf.io\"\n }"}
          minLines={10}
          width="100%"
          readOnly
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            highlightActiveLine: true,
            showLineNumbers: true,
          }}
        />
        <Typography sx={{ marginTop: '20px', marginBottom: '20px' }}>
          Then we use this information in template by calling a variable with <b>@data</b> prefix
        </Typography>
        <AceEditor
          mode="html"
          fontSize={15}
          theme="chaos"
          minLines={10}
          width="100%"
          /* eslint-disable-next-line */
          value={"<h1>Hello {{ @data.name }}</h1>\n<p>My email is {{ @data.email }}</p>"}
          maxLines={Infinity}
          readOnly
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            highlightActiveLine: true,
            showLineNumbers: true,
          }}
        />
        <Typography sx={{ marginTop: '20px', marginBottom: '20px' }}>
          You can see result in live preview at the right.
        </Typography>
        <img style={style.img} src={img04} alt="" />
        <Typography component="h3" style={style.h3} variant="h5">
          Full Liquid documentation
        </Typography>
        <Typography>
          If you want to go further, you can read the full documenation at{` `}
          <a href="https://shopify.github.io/liquid/" target="_blank" rel="noreferrer">
            https://shopify.github.io/liquid/
          </a>
          .
        </Typography>
      </Container>
      <Container style={style.root} sx={{ textAlign: 'center' }}>
        <Button
          onClick={() => navigate('/docs/templates')}
          variant="outlined"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          &lt; Creating templates
        </Button>
        <Button
          onClick={() => navigate('/docs/styling')}
          variant="contained"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          Styling your PDF &gt;
        </Button>
      </Container>
    </>
  );
};
export default Html;
