import React, { useState } from 'react';
import {
  Box,
  Container,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormHelperText,
} from '@mui/material';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router';
import Title from '../../../components/Title';
import { useFeedback } from '../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK } from '../../../utilities/feedback';

const style = {
  root: {
    marginTop: '40px',
  },
};

const DocumentInit = () => {
  const [, setFeedback] = useFeedback();
  const navigate = useNavigate();
  const [document, setDocument] = useState({
    title: '',
    payload: '{}',
    template_id: null,
  });
  const [errors, setErrors] = useState({
    title: '',
    template_id: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocument((prevDocument) => ({ ...prevDocument, [name]: value }));
  };

  const [{ data }] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/templates`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  const [, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/documents`,
      method: 'POST',
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const createDocument = () => {
    executePost({ data: document })
      .then((result) => {
        setFeedback(
          SUCCESS_FEEDBACK({
            message: "Document created. Let's go for read data",
          })
        );
        navigate(`/app/documents/${result.data.id}/edit`);
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  };

  const validForm = document.title !== '' && document.template !== null;

  return (
    <Box>
      <Title value="Initialize Document" component="h1" variant="h1" />
      <Container style={style.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Title"
              name="title"
              size="small"
              fullWidth
              error={errors.title}
              helperText={errors.title}
              value={document.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required size="small">
              <InputLabel>Template</InputLabel>
              <Select
                value={document.template_id}
                name="template_id"
                label="Template"
                error={errors.template_id}
                onChange={handleChange}
              >
                {data &&
                  data.map((row) => {
                    return <MenuItem value={row.id}>{row.title}</MenuItem>;
                  })}
              </Select>
              <FormHelperText error>{errors.template_id}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <Button
              disabled={!validForm}
              variant="contained"
              color="secondary"
              onClick={createDocument}
            >
              Create my document
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default DocumentInit;
