import React, { useState } from 'react';
import { BASE_FEEDBACK } from '../utilities/feedback';

const FeedbackContext = React.createContext();

/* eslint-disable-next-line */
const FeedbackProvider = ({ children }) => {
  const [feedback, setFeedback] = useState(BASE_FEEDBACK);

  return (
    <FeedbackContext.Provider
      /* eslint-disable-next-line */
      value={{
        feedback,
        setFeedback,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};
export { FeedbackContext, FeedbackProvider };
