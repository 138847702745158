import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
  Typography,
  Box,
  Grid,
  Button,
  Divider,
  Tabs,
  Tab,
  useTheme,
  styled,
  Alert,
} from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import AceEditor from 'react-ace';
import useAxios from 'axios-hooks';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import SaveIcon from '@mui/icons-material/Save';
import PublishIcon from '@mui/icons-material/Publish';
import Cookies from 'js-cookie';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Title from '../../../components/Title';
import { useFeedback } from '../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK } from '../../../utilities/feedback';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const style = {
  button: {
    marginLeft: '10px',
  },
};

const Action = styled(Box)(({ theme }) => ({
  marginBottom: '14px',
  textAlign: 'end',
  paddingRight: '0',
  [theme.breakpoints.down('md')]: {
    textAlign: 'start',
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'end',
  },
}));

const DocumentNew = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [, setFeedback] = useFeedback();
  const [tab, setTab] = useState(0);
  const [rand, setRand] = useState(Math.random());
  const [document, setDocument] = useState({
    template: '',
    payload: '{}',
  });

  const [, executePatch] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/documents/${id}`,
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [, fetchDocument] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/documents/${id}/edit`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [, publishDocument] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/documents/publish/${id}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const updateDocument = () => {
    executePatch({ data: document }).then(() => {
      setFeedback(
        SUCCESS_FEEDBACK({
          message: 'Document updated',
        })
      );
      setRand(Math.random());
      setDocument({ ...document, state: 'draft' });
    });
  };

  const generateDocument = () => {
    publishDocument().then(() => {
      setFeedback(
        SUCCESS_FEEDBACK({
          message: 'Document published',
        })
      );
      setDocument({ ...document, state: 'created' });
      navigate('/app/documents');
    });
  };

  const handleChangeTab = (event, value) => {
    setTab(value);
  };

  const handleChangeIndex = (index) => {
    setTab(index);
  };

  const editorChange = (name, value) => {
    setDocument({ ...document, [name]: value });
  };

  const documentIcon = ({ state }) => {
    switch (state) {
      case 'draft':
        return <InfoIcon />;
      case 'pending':
        return <InfoIcon />;
      case 'wip':
        return <WarningIcon />;
      case 'error':
        return <WarningIcon />;
      case 'created':
        return <CheckCircleIcon />;
      default:
        return '';
    }
  };

  const documentColor = ({ state }) => {
    switch (state) {
      case 'draft':
        return 'info';
      case 'pending':
        return 'warning';
      case 'wip':
        return 'warning';
      case 'error':
        return 'error';
      case 'created':
        return 'success';
      default:
        return 'success';
    }
  };

  useEffect(() => {
    if (id) fetchDocument().then(({ data }) => setDocument({ ...document, ...data }));
  }, [id]);

  return (
    <Box>
      <Title value="Create Document" component="h1" variant="h1" />
      <Action>
        <Chip
          icon={documentIcon(document)}
          color={documentColor(document)}
          label={document.state}
          variant="outlined"
        />
        <Button
          disabled={document.state === 'pending' || document.state === 'wip'}
          style={style.button}
          variant="contained"
          color="secondary"
          startIcon={<SaveIcon />}
          onClick={updateDocument}
        >
          Save
        </Button>
        <Button
          disabled={document.state !== 'draft'}
          style={style.button}
          variant="contained"
          color="secondary"
          startIcon={<PublishIcon />}
          onClick={generateDocument}
        >
          Publish
        </Button>
      </Action>
      <Grid container spacing={1}>
        <Grid item xs>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
              >
                <Tab label="Sample Data" {...a11yProps(0)} />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tab}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={tab} index={0} dir={theme.direction}>
                  <AceEditor
                    mode="json"
                    fontSize={parseInt(Cookies.get('font_size'), 10)}
                    theme={Cookies.get('theme')}
                    maxLines={Cookies.get('max_lines')}
                    value={document.payload}
                    width="100%"
                    onChange={(value) => editorChange('payload', value)}
                    name="payload"
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true,
                      highlightActiveLine: true,
                      showLineNumbers: false,
                    }}
                  />
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl={`${process.env.REACT_APP_API_URL}/documents/sample/${id}?rand=${rand}`}
              renderError={() => <Alert severity="error">Invalid data Payload.</Alert>}
            />
          </Worker>
        </Grid>
      </Grid>
    </Box>
  );
};
export default DocumentNew;
