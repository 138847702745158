import React from 'react';
import { Typography } from '@mui/material';

const style = {
  h1: {
    fontSize: '1.6rem',
    borderBottom: '1px solid #1976d2',
    marginBottom: '12px',
  },
};

const Title = ({ value, variant, component, id, align }) => {
  return (
    <Typography
      align={align}
      variant={variant}
      component={component}
      noWrap
      style={style.h1}
      id={id}
    >
      {value}
    </Typography>
  );
};
export default Title;
