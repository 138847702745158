import React, { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';

const PlanContext = React.createContext();

/* eslint-disable-next-line */
const PlanProvider = ({ children }) => {
  const [, fetchPlan] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/plans`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  const [plan, setPlan] = useState({
    label: '',
    app: 0,
    app_counter: 0,
    document: 0,
    document_counter: 0,
  });

  useEffect(() => {
    fetchPlan().then(({ data }) => setPlan({ ...plan, ...data }));
  }, []);

  return (
    <PlanContext.Provider
      /* eslint-disable-next-line */
      value={{
        plan,
        setPlan,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};
export { PlanContext, PlanProvider };
