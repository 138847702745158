import { Container, CircularProgress } from '@mui/material';
import React from 'react';

const style = {
  root: {
    margin: 'auto',
    textAlign: 'center',
    marginBottom: '4px',
    marginTop: '4px',
  },
};

const Loader = () => {
  return (
    <Container style={style.root}>
      <CircularProgress color="primary" />
    </Container>
  );
};
export default Loader;
