import React, { useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Container,
  Typography,
  Alert,
  Collapse,
  CircularProgress,
  Link,
  Box,
  styled,
  Grid,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useAxios from 'axios-hooks';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import { usePlan } from '../../../hooks/usePlan';

const style = {
  avatar: {
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: '#9c27b0',
  },
  typography: {
    marginBottom: '10px',
  },
  submit: {
    marginTop: '20px',
  },
  register: {
    marginTop: '20px',
    display: 'block',
    textDecoration: 'none',
  },
};

const Root = styled(Box)(({ theme }) => ({
  margin: 'auto',
  width: '50%',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: '30%',
  },
}));

const Login = () => {
  const [auth, setAuth] = useState({ email: '', password: '' });
  const [, setPlan] = usePlan();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuth({ ...auth, [name]: value });
  };

  const [, fetchLogin] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/login`,
      method: 'POST',
    },
    { manual: true }
  );

  const login = () => {
    setError('');
    setLoading(true);
    fetchLogin({ data: auth }).then((result) => {
      setLoading(false);
      if (result.data.error) {
        setError(result.data.error);
      } else {
        Cookies.set('token', result.data.token);
        Cookies.set('theme', result.data.theme);
        Cookies.set('api_key', result.data.api_key);
        setPlan({
          label: result.data.plan.label,
          app: parseInt(result.data.plan.app, 10),
          app_counter: parseInt(result.data.plan.app_counter, 10),
          document: parseInt(result.data.plan.document, 10),
          document_counter: parseInt(result.data.plan.document_counter, 10),
        });
        if (result.data.app !== false) {
          localStorage.setItem('app', result.data.app);
        }
        navigate('/app/');
      }
    });
  };

  return (
    <Root>
      <Container>
        <CssBaseline />
        <Avatar style={style.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography style={style.typography}>Authentication</Typography>
        <Collapse in={error}>
          <Alert severity="error">{error}</Alert>
        </Collapse>
        <form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            id="email"
            onChange={handleChange}
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            size="small"
            required
            fullWidth
            name="password"
            onChange={handleChange}
            label="Password"
            type="password"
            id="password"
          />
          <Button
            style={style.submit}
            fullWidth
            variant="contained"
            color="primary"
            onClick={login}
          >
            {loading ? <CircularProgress color="secondary" size={25} /> : `Login`}
          </Button>
        </form>
      </Container>
      <Container style={style.root}>
        <Grid container>
          <Grid item xs={12}>
            <Link href="/register" style={style.register}>
              Register
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Link href="/forgot_password" style={style.register}>
              Forgot password ?
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};
export default Login;
