import Axios from 'axios';
/* eslint-disable-next-line */
import LRU from 'lru-cache';
import Cookies from 'js-cookie';
import { configure } from 'axios-hooks';

/* eslint-disable-next-line */
export const logout = () => {
  Cookies.remove('token');
  Cookies.remove('theme');
  Cookies.remove('api_key');
  localStorage.removeItem('app');
};

const axios = Axios.create({
  timeout: 180000,
  // withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

const cache = new LRU({ max: 10 });

// request interceptor to add token to request headers
axios.interceptors.request.use(
  async (config) => {
    const token = Cookies.get('token');
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);
configure({ axios, cache });
