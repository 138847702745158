import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Container,
  Typography,
  CssBaseline,
  CircularProgress,
  Avatar,
  Link,
  Box,
  styled,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import HelpIcon from '@mui/icons-material/HelpOutline';

const style = {
  avatar: {
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: '#9c27b0',
  },
  typography: {
    marginBottom: '10px',
  },
  submit: {
    marginTop: '20px',
  },
  register: {
    marginTop: '20px',
    display: 'block',
    textDecoration: 'none',
  },
};

const Root = styled(Box)(({ theme }) => ({
  margin: 'auto',
  width: '50%',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: '30%',
  },
}));

const ForgotForm = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitEmail = () => {
    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/password/forgot`, { email }).finally(() => {
      setLoading(false);
      setEmailSent(true);
    });
  };

  return (
    <Root>
      <Container>
        <CssBaseline />
        <Avatar style={style.avatar}>
          <HelpIcon />
        </Avatar>
        <Typography sryle={style.typography}>Forgot password ?</Typography>
        {emailSent === false && (
          <form>
            <TextField
              id="email"
              name="email"
              margin="normal"
              size="small"
              label="Email"
              variant="outlined"
              onChange={(event) => setEmail(event.target.value)}
              required
              fullWidth
            />
            <Button
              variant="contained"
              style={style.submit}
              fullWidth
              color="primary"
              onClick={submitEmail}
            >
              {!loading ? 'Submit' : <CircularProgress color="inherit" />}
            </Button>
          </form>
        )}
        {emailSent && (
          <Alert severity="info">
            If your account exists, you will receive an email with instructions for reset your
            password.
          </Alert>
        )}
        <Container style={style.root}>
          <Link href="/login" style={style.register}>
            Go back
          </Link>
        </Container>
      </Container>
    </Root>
  );
};
export default ForgotForm;
