import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import './services/axios';
import Layout from './containers/Layout';
import { FeedbackProvider } from './contexts/FeedbackContext';
import { PlanProvider } from './contexts/PlanContext';
import Feedback from './components/Feedback';
import Login from './containers/Authentification/Login';
import Register from './containers/Authentification/Register';
import Homepage from './containers/Homepage';
import Documentation from './containers/Documentation';
import ForgotForm from './containers/Authentification/ForgotForm';
import ResetForm from './containers/Authentification/ResetForm';
import logo from './assets/logo.png';

export default function App() {
  return (
    <FeedbackProvider>
      <PlanProvider>
        <Helmet>
          <title>FastPDF.io - Automate your PDF generation</title>
          <meta
            name="description"
            content="Use Richtext interface to generate awesome PDF files for your business"
          />
          <meta
            name="keywords"
            content="pdf generation api, generate pdf, wickedpdf, wicked_pdf, html to pdf"
          />
          <meta property="og:site_name" content="FastPDF.io" />
          <meta property="og:title" content="Automate your PDF generation." />
          <meta property="og:image" content={logo} />
          <meta
            property="og:description"
            content="Use Richtext interface to generate awesome PDF files for your business"
          />
          {/* <meta property="og:image" content="" /> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://fastpdf.io" />
        </Helmet>
        <Router>
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotForm />} />
            <Route path="/reset/:token" element={<ResetForm />} />
            <Route path="/app/*" element={<Layout />} />
            <Route path="/docs/*" element={<Documentation />} />
            <Route path="/" element={<Homepage />} />
          </Routes>
        </Router>
        <Feedback />
      </PlanProvider>
    </FeedbackProvider>
  );
}
