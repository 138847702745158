export const PAGE_SIZES = [
  { format: 'A0', size: '841 x 1189 mm' },
  { format: 'A1', size: '594 x 841 mm' },
  { format: 'A2', size: '420 x 594 mm' },
  { format: 'A3', size: '297 x 420 mm' },
  { format: 'A4', size: '210 x 297 mm' },
  { format: 'A5', size: '148 x 210 mm	' },
  { format: 'A6', size: '105 x 148 mm	' },
  { format: 'A7', size: '74 x 105 mm	' },
  { format: 'A8', size: '52 x 74 mm' },
  { format: 'A9', size: '37 x 52 mm	' },
];
export default PAGE_SIZES;
