import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Alert,
  LinearProgress,
  Box,
  Link,
} from '@mui/material';
import useAxios from 'axios-hooks';
import Moment from 'react-moment';
import BarCount from './components/BarCount';
import DoughnutTemplate from './components/DoughnutTemplate';

const style = {
  chart: {},
  title: {
    textAlign: 'center',
  },
  counter: {
    textAlign: 'center',
    fontSize: '35px',
  },
};

const Statistics = () => {
  const [{ data: templateCounter }] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/stats/templates_count`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  const [{ data: documentsCounter }] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/stats/documents_count`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  const [{ data: plan }] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/plans`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  const getColor = () => {
    if (plan.document_counter === plan.document) {
      return 'error';
    }
    if ((plan.document_counter / plan.document) * 100 > 80) {
      return 'warning';
    }
    return 'success';
  };

  return (
    <Grid container spacing={2}>
      {plan && plan.label === 'AppSumo' && (
        <Grid item xs={12}>
          <Alert severity="info">
            <>
              You are currently in AppSumo special plan (49€ for one year){` `}
              <b>
                [<Link href="/app/plans/choose">Change plan</Link>]
              </b>
            </>
          </Alert>
        </Grid>
      )}
      {!localStorage.getItem('app') && (
        <Grid item xs={12}>
          <Alert severity="warning">Get started, create your first app...</Alert>
        </Grid>
      )}
      {plan && plan.label === 'Trial' && (
        <Grid item xs={12}>
          <Alert severity="warning">
            <>
              Your free trial expire at {` `}
              <b>
                <Moment date={plan.expired_at} />
                {` `}
              </b>
              <b>
                [<Link href="/app/plans/choose">Upgrade</Link>]
              </b>
            </>
          </Alert>
        </Grid>
      )}
      {plan && plan.label === 'Free' && (
        <Grid item xs={12}>
          <Alert severity="warning">
            <>
              <b>Your free Trial is expired.</b>
              Your have been downgraded to free plan.{` `}
              <b>
                [<Link href="/app/plans/choose">Upgrade</Link>]
              </b>
            </>
          </Alert>
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="p" color="secondary" style={style.title}>
              Templates
            </Typography>
            <Typography component="p" color="secondary" style={style.counter}>
              {/* eslint-disable-next-line */}
              {templateCounter ? templateCounter : 0}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography
              variant="h6"
              component="p"
              sx={{ color: (theme) => theme.palette.success.main }}
              style={style.title}
            >
              Documents published
            </Typography>
            <Typography
              component="p"
              sx={{ color: (theme) => theme.palette.success.main }}
              style={style.counter}
            >
              {documentsCounter && documentsCounter.created ? documentsCounter.created : 0}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography
              variant="h6"
              component="p"
              sx={{ color: (theme) => theme.palette.warning.main }}
              style={style.title}
            >
              Generations in progress
            </Typography>
            <Typography
              component="p"
              sx={{ color: (theme) => theme.palette.warning.main }}
              style={style.counter}
            >
              {documentsCounter && documentsCounter.pending ? documentsCounter.pending : 0}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              Documents generated
            </Typography>
            {plan && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    color={getColor()}
                    value={(plan.document_counter / plan.document) * 100}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">
                    {` ${Math.round(plan.document_counter)}/${Math.round(plan.document)}`}
                  </Typography>
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} style={style.chart}>
        <BarCount />
      </Grid>
      <Grid item xs={12} md={6} style={style.chart}>
        <DoughnutTemplate />
      </Grid>
    </Grid>
  );
};
export default Statistics;
