import React, { useState } from 'react';
import {
  Box,
  Container,
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  styled,
} from '@mui/material';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router';
import Title from '../../../components/Title';
import { useFeedback } from '../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK } from '../../../utilities/feedback';
import htmlIcon from '../../../assets/html.png';
import editorIcon from '../../../assets/writer.png';

const style = {
  root: {
    marginTop: '40px',
  },
  mod: {
    display: 'inline-flex',
    marginBottom: '8px',
  },
  paper: {
    opacity: 0.5,
  },
  active: {
    opacity: 1,
  },
  img: {
    width: '80%',
    margin: '8px',
  },
};

const Mod = styled(Paper)(({ theme }) => ({
  width: '23%',
  marginRight: '8px',
  textAlign: 'center',
  cursor: 'pointer',
  padding: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '50%',
  },
  [theme.breakpoints.up('md')]: {
    width: '23%',
  },
}));

const TemplateInit = () => {
  const [, setFeedback] = useFeedback();
  const navigate = useNavigate();
  const [template, setTemplate] = useState({
    title: '',
    app_id: null,
    mod: 'simple',
  });
  const [errors, setErrors] = useState({
    title: '',
    app_id: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplate((prevTemplate) => ({ ...prevTemplate, [name]: value }));
  };

  const handleMod = (value) => {
    setTemplate({ ...template, mod: value });
  };

  const [{ data }] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/apps`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  const [, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/templates`,
      method: 'POST',
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const createTemplate = () => {
    executePost({ data: template })
      .then((result) => {
        setFeedback(
          SUCCESS_FEEDBACK({
            message: "Template created. Let's go for customization",
          })
        );
        navigate(`/app/templates/${result.data.id}/edit`);
      })
      .catch((err) => {
        setErrors(err.response.data);
      });
  };

  const validTemplate = template.title !== '';

  return (
    <Box>
      <Title value="Initialize Template" component="h1" variant="h1" />
      <Container style={style.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              label="Title"
              name="title"
              size="small"
              fullWidth
              error={errors.title}
              helperText={errors.title}
              value={template.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required size="small">
              <InputLabel>Application</InputLabel>
              <Select
                value={template.app_id}
                name="app_id"
                label="Application"
                error={errors.app_id}
                onChange={handleChange}
                required
              >
                {data &&
                  data.map((row) => {
                    return <MenuItem value={row.id}>{row.name}</MenuItem>;
                  })}
              </Select>
              <FormHelperText error>{errors.app_id}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} />
          <Grid item xs={12}>
            <Box style={style.mod}>
              <Mod
                style={template.mod === 'advanced' ? style.active : style.paper}
                elevation={3}
                onClick={() => handleMod('advanced')}
              >
                <img style={style.img} src={htmlIcon} alt="From HTML/CSS" />
                <Typography>From HTML/CSS</Typography>
              </Mod>
              <Mod
                style={template.mod === 'simple' ? style.active : style.paper}
                elevation={3}
                onClick={() => handleMod('simple')}
              >
                <img style={style.img} src={editorIcon} alt="From WISIWIG Editor" />
                <Typography>From WYSIWYG Editor</Typography>
              </Mod>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={!validTemplate}
              variant="contained"
              color="secondary"
              onClick={createTemplate}
            >
              Create my template
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default TemplateInit;
