import React, { useState, useEffect } from 'react';
import { Container, Box, Grid, TextField, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/ext-language_tools';
import useAxios from 'axios-hooks';
import '@react-pdf-viewer/core/lib/styles/index.css';
import SaveIcon from '@mui/icons-material/Save';
import { usePlan } from '../../../hooks/usePlan';
import Title from '../../../components/Title';
import { useFeedback } from '../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK } from '../../../utilities/feedback';

const style = {
  root: {
    marginTop: '40px',
  },
};

const AppNew = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [plan, setPlan] = usePlan();
  const [, setFeedback] = useFeedback();
  const [app, setApp] = useState({
    name: '',
  });
  const [errors, setErrors] = useState({
    name: '',
  });

  const [, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/apps`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [, executePatch] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/apps/${id}`,
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [, fetchApp] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/apps/${id}/edit`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const createApp = () => {
    executePost({ data: app })
      .then(() => {
        setFeedback(
          SUCCESS_FEEDBACK({
            message: 'App created',
          })
        );
        localStorage.setItem('app', true);
        setPlan({ ...plan, app_counter: plan.app_counter + 1 });
        navigate('/app/apps');
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const updateApp = () => {
    executePatch({ data: app })
      .then(() => {
        setFeedback(
          SUCCESS_FEEDBACK({
            message: 'App updated',
          })
        );
        navigate('/app/apps');
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const createOrUpdate = () => (id ? updateApp() : createApp());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApp({ ...app, [name]: value });
  };

  useEffect(() => {
    if (id) fetchApp().then(({ data }) => setApp({ ...app, ...data }));
  }, [id]);

  return (
    <Box>
      <Title value="Create Application" component="h1" variant="h1" />
      <Container style={style.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              label="Name"
              name="name"
              error={errors.name}
              helperText={errors.name}
              value={app.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              disabled={app.name === ''}
              startIcon={<SaveIcon />}
              onClick={() => createOrUpdate()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default AppNew;
