import React from 'react';
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material';

const AlertBox = ({
  open,
  onClose,
  submit,
  title,
  text,
  canceltext = 'Annuler',
  submitText = 'Envoyer',
}) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ width: '100%' }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {canceltext}
        </Button>
        {submit && (
          <Button onClick={submit} color="primary" variant="contained">
            {submitText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertBox;
