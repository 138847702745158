export const BASE_FEEDBACK = {
  open: false,
  severity: 'success',
  message: '',
};

export const SUCCESS_FEEDBACK = ({ message = '' }) => ({
  open: true,
  severity: 'success',
  message,
});

export const INFO_FEEDBACK = ({ message = '' }) => ({
  open: true,
  severity: 'info',
  message,
});

export const WARNING_FEEDBACK = ({ message = '' }) => ({
  open: true,
  severity: 'warning',
  message,
});

export const ERROR_FEEDBACK = ({ message = '' }) => ({
  open: true,
  severity: 'error',
  message,
});
