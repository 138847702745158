import React, { useEffect } from 'react';
import { Typography, Container, Button, ListItem, List } from '@mui/material';
import { useNavigate } from 'react-router';
import img01 from '../../../assets/docs/01.png';
import img02 from '../../../assets/docs/02.png';
import img03 from '../../../assets/docs/03.png';

const style = {
  root: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  h3: {
    borderBottom: '1px solid #1976d2',
    marginBottom: '20px',
  },
  img: {
    width: '100%',
    MozBoxShadow: '10px 10px 5px #ccc',
    WebkitBoxShadow: '10px 10px 5px #ccc',
    boxShadow: '10px 10px 5px #ccc',
    MozBorderRadius: '25px',
    WebkitBorderRadius: '25px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};

const Templates = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container style={style.root}>
        <Typography component="h2" variant="h4">
          Creating templates
        </Typography>
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Creating a template
        </Typography>
        <Typography>
          To create a template, go to the <b>Template page</b> and click on{` `}
          <b>Create a template</b>.
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Select a application, give a name and choose a template&apos;s mode
        </Typography>
        <img style={style.img} src={img01} alt="" />
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Simple VS advanced mode
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Simple Mode provide a WYSIWYG component for generating simple PDF template. But you can
          use a JSON payload to provide dynamic data into your template.
        </Typography>
        <img style={style.img} src={img02} alt="" />
        <Typography>Advanced Mode is composed of four components :</Typography>
        <List>
          <ListItem>A HTML editor to structure your PDF</ListItem>
          <ListItem>A CSS editor to style your PDF</ListItem>
          <ListItem>a JSON payload to provide dynamic data in your PDF</ListItem>
          <ListItem>
            A settings component to customize PDF rendering (margins, orientation etc...)
          </ListItem>
        </List>
        <img style={style.img} src={img03} alt="" />
      </Container>
      <Container style={style.root}>
        <Typography component="h3" style={style.h3} variant="h5">
          Two actions are possible
        </Typography>
        <Typography>You can enable/disable preview</Typography>
        <Typography>Save template and see live preview</Typography>
      </Container>
      <Container style={style.root} sx={{ textAlign: 'center' }}>
        <Button
          onClick={() => navigate('/docs')}
          variant="outlined"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          &lt; Creating Account
        </Button>
        <Button
          onClick={() => navigate('/docs/html')}
          variant="contained"
          color="secondary"
          sx={{ margin: '10px' }}
        >
          Dynamic data &gt;
        </Button>
      </Container>
    </>
  );
};
export default Templates;
