import React, { useState } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router';
import useAxios from 'axios-hooks';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CachedIcon from '@mui/icons-material/Cached';
import { useFeedback } from '../../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK, ERROR_FEEDBACK } from '../../../../utilities/feedback';
import AlertBox from '../../../../components/AlertBox';

const Actions = ({ id, mod, refetch }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const [, setFeedback] = useFeedback();
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [, destroyTemplate] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/templates/${id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [, duplicateTemplate] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/templates/duplicate/${id}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [, switchTemplate] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/templates/switch/${id}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const switchTemplateMod = () => {
    switchTemplate()
      .then((result) => {
        refetch();
        navigate(`/app/templates/${result.data.id}/edit`);
      })
      .catch((err) =>
        setFeedback(
          ERROR_FEEDBACK({
            message: err.response.data.error,
          })
        )
      );
  };

  const copyTemplate = () => {
    duplicateTemplate()
      .then((result) => {
        setFeedback(
          SUCCESS_FEEDBACK({
            message: 'Template duplicated',
          })
        );
        refetch();
        navigate(`/app/templates/${result.data.id}/edit`);
      })
      .catch((err) =>
        setFeedback(
          ERROR_FEEDBACK({
            message: err.response.data.error,
          })
        )
      );
  };

  const deleteTemplate = () => {
    destroyTemplate()
      .then(() => {
        setFeedback(
          SUCCESS_FEEDBACK({
            message: 'Template removed',
          })
        );
        refetch();
      })
      .catch((err) =>
        setFeedback(
          ERROR_FEEDBACK({
            message: err.response.data.error,
          })
        )
      );
    setOpen(false);
  };

  return (
    <>
      <AlertBox
        open={open}
        onClose={() => setOpen(false)}
        submit={deleteTemplate}
        title="Delete"
        text="Do you want to remove this template ?"
        canceltext="Cancel"
        submitText="Delete"
      />
      <IconButton onClick={handleMenuOpen} size="large">
        <MoreVertIcon color="secondary" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => navigate(`/app/templates/${id}/edit`)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={copyTemplate}>
          <ListItemIcon>
            <FileCopyIcon />
          </ListItemIcon>
          Duplicate
        </MenuItem>
        <MenuItem onClick={switchTemplateMod}>
          <ListItemIcon>
            <CachedIcon />
          </ListItemIcon>
          Switch to {mod === 'simple' ? 'Advanced' : 'Simple'} mod
        </MenuItem>
        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};
export default Actions;
