import React, { useState } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import useAxios from 'axios-hooks';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import { usePlan } from '../../../../hooks/usePlan';
import { useFeedback } from '../../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK } from '../../../../utilities/feedback';
import AlertBox from '../../../../components/AlertBox';

const Actions = ({ id, state, refetch }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [plan, setPlan] = usePlan();
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const [, setFeedback] = useFeedback();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [, fetchDownload] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/documents/download/${id}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [, publishDocument] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/documents/publish/${id}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [, destroyDocument] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/documents/${id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const downloadDocument = () => {
    fetchDownload().then((result) => {
      setFeedback(
        SUCCESS_FEEDBACK({
          message: 'Document downloaded',
        })
      );
      window.open(result.data.url, '_blank');
    });
  };

  const deleteDocument = () => {
    destroyDocument().then(() => {
      setFeedback(
        SUCCESS_FEEDBACK({
          message: 'Document removed',
        })
      );
      refetch();
      setOpen(false);
    });
  };

  const generateDocument = () => {
    publishDocument().then(() => {
      setFeedback(
        SUCCESS_FEEDBACK({
          message: 'Document published',
        })
      );
      setPlan({ ...plan, document_counter: plan.document_counter + 1 });
      refetch();
    });
  };

  return (
    <>
      <AlertBox
        open={open}
        onClose={() => setOpen(false)}
        submit={deleteDocument}
        title="Are your sure ?"
        text="Do you want to remove this document ?"
        canceltext="Cancel"
        submitText="Delete"
      />
      <IconButton onClick={handleMenuOpen} size="large">
        <MoreVertIcon color="secondary" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => navigate(`/app/documents/${id}/edit`)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem
          onClick={generateDocument}
          disabled={state !== 'draft' || plan.document_counter >= plan.document}
        >
          <ListItemIcon>
            <PublishIcon />
          </ListItemIcon>
          Publish
        </MenuItem>
        <MenuItem onClick={downloadDocument} disabled={state !== 'created'}>
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          Download
        </MenuItem>
        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};
export default Actions;
