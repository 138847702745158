import React from 'react';
import { Typography, Container } from '@mui/material';
import success from '../../../assets/success.png';

const SuccessPlan = () => {
  return (
    <Container style={{ 'text-align': 'center' }}>
      <Typography variant="h4">Your account has been updated !</Typography>
      <Typography variant="body1" style={{ margin: 20 }}>
        Thank you for your subscription. If you have any question,you can contact us at{` `}
        <b>contact@fastpdf.io</b>
      </Typography>
      <img src={success} alt="Thank you" style={{ margin: 20, width: '25%' }} />
    </Container>
  );
};
export default SuccessPlan;
