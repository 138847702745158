import React, { useState } from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
/* eslint-disable-next-line */
import { useNavigate } from 'react-router';
import useAxios from 'axios-hooks';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFeedback } from '../../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK, ERROR_FEEDBACK } from '../../../../utilities/feedback';
import AlertBox from '../../../../components/AlertBox';

const Actions = ({ id, refetch }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const [, setFeedback] = useFeedback();
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [, destroyWebhook] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/webhook_endpoints/${id}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const deleteWebhook = () => {
    destroyWebhook()
      .then(() => {
        setFeedback(
          SUCCESS_FEEDBACK({
            message: 'Webhook removed',
          })
        );
        refetch();
      })
      .catch((response) =>
        setFeedback(
          ERROR_FEEDBACK({
            message: response.message,
          })
        )
      );
    setOpen(false);
  };

  return (
    <>
      <AlertBox
        open={open}
        onClose={() => setOpen(false)}
        submit={deleteWebhook}
        title="Delete"
        text="Do you want to remove this Webhook ?"
        canceltext="Cancel"
        submitText="Delete"
      />
      <IconButton onClick={handleMenuOpen} size="large">
        <MoreVertIcon color="secondary" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => navigate(`/app/webhook_endpoints/${id}/edit`)}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};
export default Actions;
