import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { useParams } from 'react-router-dom';
/* eslint-disable-next-line */
import { useNavigate } from 'react-router';
import useAxios from 'axios-hooks';
import SaveIcon from '@mui/icons-material/Save';
import { useFeedback } from '../../../hooks/useFeedback';
import { SUCCESS_FEEDBACK } from '../../../utilities/feedback';
import Title from '../../../components/Title';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/ext-language_tools';
import '@react-pdf-viewer/core/lib/styles/index.css';

const style = {
  root: {
    marginTop: '40px',
  },
};

const WebhookNew = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [, setFeedback] = useFeedback();
  const [webhook, setWebhook] = useState({
    target_url: '',
    app_id: null,
  });
  const [errors, setErrors] = useState({
    target_url: '',
    app_id: '',
  });

  const [, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/webhook_endpoints`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
      manual: true,
    }
  );

  const [, executePatch] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/webhook_endpoints/${id}`,
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const [{ data }] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/apps`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    {
      useCache: false,
    }
  );

  const [, fetchWebhook] = useAxios(
    {
      url: `${process.env.REACT_APP_API_URL}/webhook_endpoints/${id}/edit`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    },
    { manual: true }
  );

  const createWebhook = () => {
    executePost({ data: webhook })
      .then(() => {
        setFeedback(
          SUCCESS_FEEDBACK({
            message: 'Webhook created',
          })
        );
        navigate('/app/webhook_endpoints');
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const updateWebhook = () => {
    executePatch({ data: webhook })
      .then(() => {
        setFeedback(
          SUCCESS_FEEDBACK({
            message: 'Webhook updated',
          })
        );
        navigate('/app/webhook_endpoints');
      })
      .catch((error) => {
        setErrors(error.response.data);
      });
  };

  const createOrUpdate = () => (id ? updateWebhook() : createWebhook());

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWebhook({ ...webhook, [name]: value });
  };

  const checkForm = webhook.target_url !== '' && webhook.app !== null;

  useEffect(() => {
    /* eslint-disable-next-line */
    if (id) fetchWebhook().then(({ data }) => setWebhook({ ...webhook, ...data }));
  }, [id]);

  return (
    <Box>
      <Title value="Create Webhook" component="h1" variant="h1" />
      <Container style={style.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              required
              fullWidth
              label="Target URL"
              name="target_url"
              error={errors.target_url}
              helperText={errors.target_url}
              value={webhook.target_url}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" required>
              <InputLabel>Application</InputLabel>
              <Select
                value={webhook.app_id}
                name="app_id"
                label="Application"
                onChange={handleChange}
                error={errors.app_id}
              >
                {data &&
                  data.map((row) => {
                    return <MenuItem value={row.id}>{row.name}</MenuItem>;
                  })}
              </Select>
              <FormHelperText error>{errors.app_id}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="secondary"
              disabled={!checkForm}
              startIcon={<SaveIcon />}
              onClick={() => createOrUpdate()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default WebhookNew;
